import { Datapoints, ScoreDatapoints } from "types/cube";

export type SecondaryDatapoint = {
  datapoint: Datapoints;
  label?: string;
  isYearly?: boolean;
  calculation?: {
    datapoints: {
      datapoint: Datapoints;
      isYearly?: boolean;
    }[];
    getValue: (...args: number[]) => number;
  };
  isSecondaryGraph?: boolean;
  fetchPrev?: boolean;
  replaceWithPrevYear?: boolean;
};

export type TimeSeriesConfig = {
  activeMonth?: number;
  secondaryDatapoints?: SecondaryDatapoint[];
  historicalComparison?: Datapoints;
  isMOM?: boolean;
  isSecondaryGraph?: boolean;
  hasActiveMonth?: boolean;
  startYear?: number;
  startMonth?: number;
  /**
   * shows monthly graph button
   */
  hasMonthly?: boolean;
};

interface Config {
  [x: string]: TimeSeriesConfig;
}

export const timeSeriesDatapointConfig = {
  [Datapoints.HOME_VALUE]: {
    hasActiveMonth: true,
    hasMonthly: true,
    secondaryDatapoints: [
      {
        label: "Fair Home Value",
        datapoint: Datapoints.FAIR_HOME_VALUE,
        isSecondaryGraph: true,
      },
    ],
  },
  [Datapoints.HOME_VALUE_GROWTH_RATE]: {
    historicalComparison: Datapoints.HOME_VALUE,
    hasActiveMonth: true,
    hasMonthly: true,
    secondaryDatapoints: [
      {
        label: "CPI Inflation YoY",
        datapoint: Datapoints.CPI_YOY_PERCENTAGE,
        isSecondaryGraph: true,
      },
    ],
  },
  [Datapoints.HOME_VALUE_FIVE_YEAR_GROWTH_RATE]: {
    historicalComparison: Datapoints.HOME_VALUE,
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.HOME_VALUE_TO_EARNINGS_RATIO]: {
    secondaryDatapoints: [
      { label: "Home Value", datapoint: Datapoints.HOME_VALUE },
      {
        label: "Median HH. Income",
        datapoint: Datapoints.MEDIAN_HOUSEHOLD_INCOME,
        isYearly: true,
      },
    ],
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.HOME_VALUE_TO_ANNUAL_RENT_RATIO]: {
    secondaryDatapoints: [
      {
        label: "Home Value",
        datapoint: Datapoints.HOME_VALUE,
      },
      {
        label: "Annual Rent for Houses",
        datapoint: Datapoints.RENT_FOR_HOUSES,
        calculation: {
          datapoints: [{ datapoint: Datapoints.RENT_FOR_HOUSES }],
          getValue: (a) => a * 12,
        },
      },
    ],
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.OVER_UNDER_VALUED_PERCENTAGE]: {
    secondaryDatapoints: [
      { label: "Typical Home Price", datapoint: Datapoints.HOME_VALUE },
      {
        label: "Fair Home Value",
        datapoint: Datapoints.FAIR_HOME_VALUE,
      },
    ],
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.TYPICAL_HOUSE_PAYMENT]: {
    secondaryDatapoints: [
      {
        label: "Principle & Interest",
        datapoint: Datapoints.PRINCIPLE_INTEREST,
      },
      {
        label: "Insurance, Taxes and misc costs",
        datapoint: Datapoints.INSURANCE_TAXES_MISC_COSTS,
      },
      {
        label: "Mortgage Rate",
        datapoint: Datapoints.MORTGAGE_RATE,
      },
    ],
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.SALARY_TO_AFFORD_HOUSE]: {
    secondaryDatapoints: [
      {
        label: "Mortgage Payment (Monthly)",
        datapoint: Datapoints.TYPICAL_HOUSE_PAYMENT,
      },
      {
        label: "Mortgage Payment (Annual)",
        datapoint: Datapoints.TYPICAL_YEARLY_HOUSE_PAYMENT,
      },
      {
        label: "Median Houshold Income",
        datapoint: Datapoints.MEDIAN_HOUSEHOLD_INCOME,
        isSecondaryGraph: true,
      },
    ],
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.HOUSE_PAYMENT_RATE_OF_MED_INCOME]: {
    secondaryDatapoints: [
      {
        label: "Yearly House Payment",
        datapoint: Datapoints.TYPICAL_YEARLY_HOUSE_PAYMENT,
      },
      {
        label: "Median HH. Income",
        isYearly: true,
        datapoint: Datapoints.MEDIAN_HOUSEHOLD_INCOME,
      },
      {
        label: "Mortgage Rate",
        datapoint: Datapoints.MORTGAGE_RATE,
      },
    ],
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.PROPERTY_TAX_RATE]: {
    hasMonthly: true,
    hasActiveMonth: true,
  },
  [Datapoints.BUY_VERSUS_RENT_PERCENTAGE]: {
    secondaryDatapoints: [
      {
        label: "Typical Yearly House Payment",
        datapoint: Datapoints.TYPICAL_YEARLY_HOUSE_PAYMENT,
      },
      {
        label: "Yearly Apartment Rent",
        datapoint: Datapoints.YEARLY_APARTMENT_RENT,
      },
    ],
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.PERCENT_CRASH_GREAT_RECESSION]: {
    isMOM: true,
  },
  [Datapoints.TOTAL_ACTIVE_INVENTORY]: {
    hasActiveMonth: true,
    startYear: 2017,
    hasMonthly: true,
  },
  [Datapoints.SHARE_OF_LISTINGS_WITH_PRICE_CUT]: {
    startYear: 2017,
    secondaryDatapoints: [
      {
        label: "Listings with price cut",
        datapoint: Datapoints.LISTINGS_WITH_PRICE_CUT,
      },
      {
        label: "Total Inventory",
        datapoint: Datapoints.TOTAL_INVENTORY,
      },
    ],
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.DAYS_ON_MARKET]: {
    hasActiveMonth: true,
    startYear: 2017,
    hasMonthly: true,
  },
  [Datapoints.YOY_INVENTORY_CHANGE_PERCENTAGE]: {
    historicalComparison: Datapoints.TOTAL_ACTIVE_INVENTORY,
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.YOY_DAYS_ON_MARKET]: {
    hasActiveMonth: true,
    historicalComparison: Datapoints.DAYS_ON_MARKET,
    hasMonthly: true,
  },
  [Datapoints.INVENTORY_PERCENTAGE_OF_HOUSES]: {
    hasActiveMonth: true,
    startYear: 2017,
    secondaryDatapoints: [
      {
        datapoint: Datapoints.TOTAL_ACTIVE_INVENTORY,
        label: "Total Active Inventory",
      },
      {
        datapoint: Datapoints.ABSENTEE_OWNED_PLUS_OWNERSHIP_HHS,
        label: "Absentee Owned + Ownership HH's",
        calculation: {
          datapoints: [
            { datapoint: Datapoints.ABSENTEE_OWNED_UNITS, isYearly: true },
            { datapoint: Datapoints.OWNERSHIP_HHS },
          ],
          getValue: (a, b) => a + b,
        },
      },
    ],
    hasMonthly: true,
  },
  [Datapoints.MOM_ACTIVE_INVENTORY_CHANGE_PERCENTAGE]: {
    isMOM: true,
    secondaryDatapoints: [
      {
        datapoint: Datapoints.TOTAL_ACTIVE_INVENTORY,
      },
    ],
  },
  [Datapoints.POPULATION]: {
    hasMonthly: true,
  },
  [Datapoints.COLLEGE_DEGREE_RATE]: {
    secondaryDatapoints: [
      {
        label: "Population with Bachelor’s or Higher:",
        datapoint: Datapoints.POPULATION_WITH_BACHELORS_OR_HIGHER,
      },
      {
        label: "25+ Age Population",
        datapoint: Datapoints.TOTAL_EDUCATION_POPULATION,
      },
    ],
  },
  [Datapoints.MEDIAN_HOUSEHOLD_INCOME]: {
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.POPULATION_GROWTH_RATE]: {
    historicalComparison: Datapoints.POPULATION,
  },
  [Datapoints.MEDIAN_INCOME_GROWTH_RATE]: {
    historicalComparison: Datapoints.MEDIAN_HOUSEHOLD_INCOME,
    hasMonthly: true,
    hasActiveMonth: true,
  },
  [Datapoints.HOME_OWNERSHIP_RATE]: {
    secondaryDatapoints: [
      { datapoint: Datapoints.OWNERSHIP_HHS, label: "Ownership HH's" },
      { datapoint: Datapoints.HOUSEHOLDS, label: "Total Households" },
    ],
  },
  [Datapoints.MORTGAGED_HOME_PERCENTAGE]: {
    secondaryDatapoints: [
      {
        datapoint: Datapoints.HOUSING_UNITS_WITH_A_MORTGAGE,
        label: "Mortgaged Homes",
      },
      { datapoint: Datapoints.OWNERSHIP_HHS, label: "Ownership HH's" },
    ],
  },
  [Datapoints.MEDIAN_AGE]: {},
  [Datapoints.POVERTY_PERCENTAGE]: {
    secondaryDatapoints: [
      {
        datapoint: Datapoints.POPULATION_LIVING_IN_POVERTY,
        label: "Population Living in Poverty",
      },
      { datapoint: Datapoints.POPULATION, label: "Population" },
    ],
  },
  [Datapoints.HOUSING_UNITS]: {},
  [Datapoints.HOUSING_UNIT_GROWTH]: {
    historicalComparison: Datapoints.HOUSING_UNITS,
  },
  [Datapoints.MOM_HOME_VALUE_GROWTH]: {
    isMOM: true,
  },
  [Datapoints.ABSENTEE_OWNER_PERCENTAGE]: {
    secondaryDatapoints: [
      {
        label: "Absentee Owned Units",
        datapoint: Datapoints.ABSENTEE_OWNED_UNITS,
        isYearly: true,
      },
      {
        label: "Absentee Owned + Ownership HH's",
        datapoint: Datapoints.ABSENTEE_OWNED_PLUS_OWNERSHIP_HHS,
        calculation: {
          datapoints: [
            { datapoint: Datapoints.ABSENTEE_OWNED_UNITS, isYearly: true },
            { datapoint: Datapoints.OWNERSHIP_HHS },
          ],
          getValue: (a, b) => a + b,
        },
      },
    ],
  },
  [Datapoints.RENT_FOR_APARTMENTS]: {
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.RENT_FOR_HOUSES]: {
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.RENT_PERCENTAGE_OF_INCOME]: {
    secondaryDatapoints: [
      {
        label: "Yearly Apartment Rent",
        datapoint: Datapoints.YEARLY_APARTMENT_RENT,
      },
      {
        label: "Med. HH. Income",
        isYearly: true,
        datapoint: Datapoints.MEDIAN_HOUSEHOLD_INCOME,
      },
    ],
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.CAP_RATE]: {
    secondaryDatapoints: [
      {
        label: "Gross Rent Income",
        datapoint: Datapoints.GROSS_RENT_INCOME,
      },
      {
        label: "Expenses",
        datapoint: Datapoints.EXPENSES,
      },
      {
        label: "Net Rent Income",
        datapoint: Datapoints.NET_RENT_INCOME,
      },
      {
        label: "Typical Home Price",
        datapoint: Datapoints.HOME_VALUE,
      },
      {
        label: "10-Year Treasury Yield",
        datapoint: Datapoints.MONTHLY_TREASURY_AVG_YIELD,
        isSecondaryGraph: true,
      },
    ],
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.TOTAL_MIGRATION]: {},
  [Datapoints.MIGRATION_PERCENTAGE]: {
    secondaryDatapoints: [
      {
        label: "Migration Total",
        datapoint: Datapoints.TOTAL_MIGRATION,
      },
      {
        label: "Population",
        datapoint: Datapoints.MIGRATION_POPULATION,
      },
    ],
  },
  [Datapoints.RENT_GROWTH_YOY]: {
    historicalComparison: Datapoints.RENT_FOR_APARTMENTS,
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.PER_CHANGE_HOME_VALUE_JUNE]: {
    isMOM: true,
    startYear: 2022,
    startMonth: 6,
  },
  [Datapoints.MEDIAN_LISTING_PRICE]: {
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.MEDIAN_LISTING_PRICE_YOY]: {
    historicalComparison: Datapoints.MEDIAN_LISTING_PRICE,
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.NEW_LISTING_COUNT]: {
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.NEW_LISTING_COUNT_YOY]: {
    historicalComparison: Datapoints.NEW_LISTING_COUNT,
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.FOR_SALE_INVENTORY_V_AVG]: {
    hasMonthly: true,
    hasActiveMonth: true,
    secondaryDatapoints: [
      {
        label: "For Sale Inventory:",
        datapoint: Datapoints.TOTAL_ACTIVE_INVENTORY,
        isYearly: true,
      },
      {
        label: "Long Term Average:",
        datapoint: Datapoints.LONG_TERM_FSI_AVG,
      },
    ],
  },
  [Datapoints.BUILDING_PERMITS]: {
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.SINGLE_FAMILY_UNITS_PERMITTED]: {
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.MULTI_FAMILY_UNITS_PERMITTED]: {
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_BUILDING_PERMITS]: {
    hasActiveMonth: true,
    hasMonthly: true,
  },
  // [Datapoints.TRAILING_TWELVE_MONTHS_SINGLE_PERMITS]: {
  //   hasActiveMonth: true,
  //   hasMonthly: true,
  // },
  // [Datapoints.TRAILING_TWELVE_MONTHS_MULTI_PERMITS]: {
  //   hasActiveMonth: true,
  //   hasMonthly: true,
  // },
  // [Datapoints.BUILDING_PERMIT_PERCENTAGE]: {
  //   hasActiveMonth: true,
  //   hasMonthly: true,
  //   secondaryDatapoints: [
  //     {
  //       label: "Building Permits",
  //       datapoint: Datapoints.TRAILING_TWELVE_MONTHS_BUILDING_PERMITS,
  //       isYearly: true,
  //     },
  //     {
  //       label: "Population",
  //       datapoint: Datapoints.MIGRATION_POPULATION,
  //       replaceWithPrevYear: true,
  //     },
  //   ],
  // },
  [Datapoints.VACANCY_RATE]: {
    secondaryDatapoints: [
      {
        label: "Vacant Units",
        datapoint: Datapoints.VACANT_HOUSE,
      },
      {
        label: "Housing Units",
        datapoint: Datapoints.HOUSING_UNITS,
      },
    ],
  },
  [Datapoints.EMPLOYMENT_LEVEL]: {
    hasMonthly: true,
  },
  [Datapoints.UNEMPLOYMENT_RATE]: {
    hasActiveMonth: true,
    hasMonthly: true,
  },
  [Datapoints.UNEMPLOYMENT_LEVEL]: {
    hasMonthly: true,
  },
  [Datapoints.TOTAL_NONFARM_PAYROLL]: {
    hasMonthly: true,
  },
  [Datapoints.TOTAL_PRIVATE_HOURLY_EARNINGS]: {
    hasMonthly: true,
  },
  [Datapoints.TOTAL_PRIVATE_WEEKLY_HOURS]: {
    hasMonthly: true,
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_TOTAL_PRIVATE_WEEKLY_HOURS]: {
    hasMonthly: true,
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_TOTAL_PRIVATE_HOURLY_EARNINGS]: {
    hasMonthly: true,
  },
  [Datapoints.TRAILING_WEEKLY_EARNINGS]: {
    hasMonthly: true,
  },
  [Datapoints.VOTING_DEMOCRAT_PERCENTAGE]: {
    secondaryDatapoints: [
      {
        label: "Vote Republican %",
        datapoint: Datapoints.VOTING_REPUBLICAN_PERCENTAGE,
        isSecondaryGraph: true,
      },
    ],
  },
  [Datapoints.VOTING_REPUBLICAN_PERCENTAGE]: {
    secondaryDatapoints: [
      {
        label: "Vote Democrat %",
        datapoint: Datapoints.VOTING_DEMOCRAT_PERCENTAGE,
        isSecondaryGraph: true,
      },
    ],
  },
  [ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE]: {
    hasMonthly: true,
    hasActiveMonth: true,
  },
  [ScoreDatapoints.DAYS_ON_MARKET_SCORE]: {
    hasMonthly: true,
    hasActiveMonth: true,
  },
  [ScoreDatapoints.RECENT_APPRECIATION_SCORE]: {
    hasMonthly: true,
    hasActiveMonth: true,
  },
  [ScoreDatapoints.MORTGAGE_RATES_SCORE]: {
    hasMonthly: true,
    hasActiveMonth: true
  },
  [ScoreDatapoints.INVENTORY_LEVELS_SCORE]: {
    hasMonthly: true,
    hasActiveMonth: true,
  },
  [ScoreDatapoints.PRICE_CUTS_SCORE]: {
    hasMonthly: true,
    hasActiveMonth: true,
  },
  [Datapoints.REMOTE_WORK_PERCENTAGE]: {
    secondaryDatapoints: [
      {
        label: "Work From Home",
        datapoint: Datapoints.WORK_FROM_HOME,
      },
      {
        label: "Total Employment",
        datapoint: Datapoints.TOTAL_EMPLOYMENT,
      },
    ],
  },
} as Config;
